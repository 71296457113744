import { CommentsService } from 'services/comments/comments.service';
import { CommentUser } from 'types/comments.type';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Translation from 'components/data/Translation';
import ComponentStoreHelpers from 'components/data/ComponentStore';
const isBusyFetching = {};

const checkIsAlreadyFetched = (entity: string) => {
    if (ComponentStoreHelpers.get('Comments') && ComponentStoreHelpers.get('Comments')[entity]) {
        return true;
    } else {
        return false;
    }
};

/**
 * Helpers for fetching, saving and removing comments.
 */
class CommentsHelpers {
    /**
     * Fetch all comments from an entity, when already in componentStore don't fetch.
     * @param entity - Entity of the assets or activeItemId of the Brick
     * @param forceUpdate - Pass true if componentStore data should be forced to update (e.g. when a comment is saved it should update even if already fetched).
     */
    static fetchComments = (entity: string, campaignId?: string | number | boolean | undefined, link?: string, forceUpdate?: boolean): void => {
        if (!entity) return;
        //Check if comments already exist, if so return data from componentStore
        const isCommentsAlreadyFetched = checkIsAlreadyFetched(entity);
        if ((!isCommentsAlreadyFetched && !isBusyFetching[entity]) || forceUpdate) {
            isBusyFetching[entity] = true;
            CommentsService.getAllComments(entity, campaignId)
                .then((res) => {
                    if (!res) return;
                    if (!ComponentStoreHelpers.get('Comments')) {
                        ComponentStoreHelpers.setData('Comments', {});
                    }
                    ComponentStoreHelpers.setModel('Comments', entity, res.comments);
                    isBusyFetching[entity] = false;
                })
                .catch(() => {
                    isBusyFetching[entity] = false;
                    SnackbarUtils.error(Translation.get('feedback.errors.oops', 'common'));
                });
        } else if (isCommentsAlreadyFetched) {
            isBusyFetching[entity] = false;
            return ComponentStoreHelpers.get('Comments')[entity];
        }
    };

    /**
     * Removes the comments from the component
     * @param entity - Entity of the assets or activeItemId of the Brick
     */
    static cleanUpComments = (entity: string): void => {
        if (!entity) return;
        if (ComponentStoreHelpers.get('Comments') && ComponentStoreHelpers.get('Comments')[entity]) {
            ComponentStoreHelpers.removeItem('Comments', entity);
        }
    };

    /**
     * Save a comment, the componentStore is automatically updated with the new comment.
     * @param {Comment} Comment - Comment data to be saved
     */
    static saveComment = (comment: {
        id: string | number;
        entity: string;
        text: string;
        campaignId?: string | number | boolean;
        parentId?: string | number;
        status?: string;
        fileName?: string;
        fileUrl?: string;
        categoryKey?: string;
        categoryLabel?: string;
        x?: string;
        y?: string;
        time?: string;
    }): void => {
        if (!comment.entity) return;
        // Save comment
        CommentsService.saveComment(comment)
            .then(() => {
                SnackbarUtils.success(Translation.get('comments.commentAdded', 'common'));
                //Fetch and force overwrite component store
                this.fetchComments(comment.entity, comment.campaignId, undefined, true);
            })
            .catch(() => {
                SnackbarUtils.error(Translation.get('feedback.errors.oops', 'common'));
            });
    };

    /**
     * Return a list with users that can be tagged with the 'react-mentions' component.
     * @param entity - Entity of the assets or activeItemId of the Brick
     * @returns {CommentUser[]} List of users that can passed to react-mentions
     */
    static fetchTaggableUsers = (entity: string): void => {
        CommentsService.getTaggableUsers(entity)
            .then((res) => {
                if (!res) return;
                const uniqueUsers = {};
                const users: CommentUser[] = [];

                res.forEach((user) => {
                    if (user.username && !uniqueUsers[user.username]) {
                        uniqueUsers[user.username] = true;
                        users.push({
                            display: user.name,
                            id: user.username,
                            profilePicture: user.profilePicture,
                            brands: user.brands,
                            departments: user.departments,
                            markets: user.markets
                        });
                    }
                });
                if (!ComponentStoreHelpers.get('Comments')) {
                    ComponentStoreHelpers.setData('Comments', { taggableUsers: users });
                }
                ComponentStoreHelpers.setModel('Comments', 'taggableUsers', users);
            })
            .catch(() => {
                SnackbarUtils.error(Translation.get('feedback.errors.oops', 'common'));
            });
    };

    /**
     * Removes the comments from the component
     * @param entity - Entity of the assets or activeItemId of the Brick
     */
    static cleanUpTaggableUsers = (): void => {
        if (ComponentStoreHelpers.get('Comments') && ComponentStoreHelpers.get('Comments')['taggableUsers']) {
            ComponentStoreHelpers.removeItem('Comments', 'taggableUsers');
        }
    };
}

export { CommentsHelpers };
