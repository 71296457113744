import { useState, useEffect } from 'react';

const useComponentSize = <T extends HTMLElement>(ref: React.RefObject<T>): { width: number; height: number } => {
    const [refFound, setRefFound] = useState<boolean>(false);
    const [width, setWidth] = useState<number>(0);
    const [height, setHeight] = useState<number>(0);

    useEffect(() => {
        const handleResize = (entries: ResizeObserverEntry[]): void => {
            if (!Array.isArray(entries) || !entries.length) return;
            const entry = entries[0];
            setWidth(entry.contentRect.width);
            setHeight(entry.contentRect.height);
        };

        const resizeObserver = new ResizeObserver((entries) => handleResize(entries));

        const observeElement = () => {
            if (ref.current) {
                resizeObserver.observe(ref.current);
                // Get initial size
                handleResize([{ contentRect: ref.current.getBoundingClientRect() } as ResizeObserverEntry]);
                setRefFound(true);
            }
        };
        const mutationObserver = new MutationObserver(observeElement);
        if (!refFound) {
            mutationObserver.observe(document, { childList: true, subtree: true });
        }

        observeElement();

        return () => {
            if (ref.current) {
                resizeObserver.unobserve(ref.current);
            }
            resizeObserver.disconnect();
            mutationObserver.disconnect();
        };
    }, [ref, refFound]);

    return { width, height };
};

export { useComponentSize };
