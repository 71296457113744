/**
 * Convert camel case text to readable text.
 * @param text - Text to convert to readable format.
 * @returns Readable text.
 */
export const camelCaseToReadable = (text: string): string => {
    return (
        text.charAt(0).toUpperCase() +
        text
            .slice(1)
            .replace(/([A-Z])/g, ' $1')
            .toLowerCase()
    );
};
