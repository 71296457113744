/**
 * Converts a string rgb color to a number array with rgb values.
 * @param CSS rgb string color.
 * @returns Rgb(a) value in an array.
 */
export const getRgbValueFromString = (colorString: string): number[] | null => {
    const rgbaRegex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)$/i;
    const match = rgbaRegex.exec(colorString);
    if (!match) {
        throw new Error('Invalid RGB/RGBA string format');
    }
    const [, r, g, b, a = 1] = match;
    return [Number(r), Number(g), Number(b), Number(a)];
};

/**
 * Convert hex color to rgb(a) color.
 * @param hex - Hex color value
 * @returns {array} RGB(A) representation of the hex color.
 */
export const convertHexToRgb = (
    hex: string
):
    | {
          r: number;
          g: number;
          b: number;
          a: number;
      }
    | undefined => {
    const regex = {
        hex3d: /^#?([0-9a-f])([0-9a-f])([0-9a-f])$/i,
        hex6d: /^#?([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i,
        hex8d: /^#?([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i
    };

    if (!hex) return;
    const matches = hex.match(regex.hex3d) || hex.match(regex.hex6d) || hex.match(regex.hex8d);
    if (!matches) return;

    const rgb = matches.slice(1).map((ch, i) => {
        const decimal = parseInt(ch.padStart(2, ch), 16);
        return i === 3 ? Math.round((decimal / 255) * 100) : decimal;
    });

    return {
        r: rgb[0],
        g: rgb[1],
        b: rgb[2],
        a: rgb[3] ? rgb[3] / 100 : 1
    };
};

/**
 * Converts an rgb(a) color to hex.
 * @param rgb - Rgb(a) color.
 * @returns An hex representation of the color.
 */
export const convertRgbToHex = (rgb: number[]): string | undefined => {
    if (!Array.isArray(rgb) || ![3, 4].includes(rgb.length)) return;

    const hex = rgb
        .map((ch, i) => {
            const decimal = i === 3 ? Math.round(ch * 255).toString(16) : ch;
            return decimal.toString(16).padStart(2, '0');
        })
        .join('');

    return '#' + hex;
};

/**
 * Converts the hex color with an hex alpha value.
 * @param hex - Hex color.
 * @param alpha - Alpha value.
 * @returns Hex color with alpha value.
 */
export const convertHexToHexAlpha = (hex: string, alpha: number): string => {
    // Ensure that alpha is within the valid range of 0 to 1
    alpha = Math.min(1, Math.max(0, alpha));

    // Convert alpha to a rounded hexadecimal value
    const alphaHex = Math.round(alpha * 255)
        .toString(16)
        .toUpperCase();

    // If the alphaHex is a single character, pad it with a leading zero
    const paddedAlphaHex = alphaHex.length === 1 ? `0${alphaHex}` : alphaHex;

    // Append the alpha value to the original hex color
    const hexWithAlpha = hex + paddedAlphaHex;

    return hexWithAlpha;
};

/**
 * Convert cmyk color to rgb color.
 * @param cmyk - CMYK color.
 * @param alpha - Alpha value (default: 1)
 * @returns Rgba color from cmyk.
 */
export const convertCmykToRgb = (cmyk: number[], alpha?: number): number[] => {
    const r = 255 * (1 - cmyk[0]) * (1 - cmyk[3]);
    const g = 255 * (1 - cmyk[1]) * (1 - cmyk[3]);
    const b = 255 * (1 - cmyk[2]) * (1 - cmyk[3]);
    return [r, g, b, alpha || 1];
};

/**
 * Convert cmyk color to hex color.
 * @param cmyk - Cmyk color.
 * @returns Hex color.
 */
export const convertCmykToHex = (cmyk: number[]): string => {
    const r = Math.round(255 * (1 - cmyk[0]) * (1 - cmyk[3]));
    const g = Math.round(255 * (1 - cmyk[1]) * (1 - cmyk[3]));
    const b = Math.round(255 * (1 - cmyk[2]) * (1 - cmyk[3]));
    return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
};

/**
 * Convert rgb color to cmyk.
 * @param rgb - Rgb color.
 * @param alpha - Alpha (default 1).
 * @returns Cmyk color.
 */
export const convertRgbToCmyk = (rgb: number[], alpha?: number): number[] => {
    // Convert RGB values to percentages
    const r = rgb[0] / 255;
    const g = rgb[1] / 255;
    const b = rgb[2] / 255;

    // Calculate maximum percentage value of RGB
    const max = Math.max(r, g, b);

    // Calculate CMY values
    const c = (max - r) / max;
    const m = (max - g) / max;
    const y = (max - b) / max;

    // Calculate K value
    const k = (1 - max) * (1 - (alpha || 1));

    // Return CMYK values
    return [c, m, y, k];
};

/**
 * Converts hex, rgb or rgba color to rgba color.
 * @param color hex, rgb or rgba color
 * @returns rgba color string
 */
export const convertColorToRgba = (color: string): string | undefined => {
    if (color.startsWith('rgb')) {
        const rgb = getRgbValueFromString(color);
        if (rgb) {
            return `rgba(${rgb[0]},${rgb[1]},${rgb[2]},${rgb[3]})`;
        }
    }
    if (color.startsWith('#')) {
        const rgb = convertHexToRgb(color);
        if (rgb) {
            return `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`;
        }
    }
    return color;
};

/**
 * Checks if the provided string is a hex color.
 * @param string - Hex color
 * @returns If the provided string is a hex color or not.
 */
export const isHexColor = (hex: string): boolean => /^#[0-9A-Fa-f]{6}$/.test(hex) || /^#[0-9A-Fa-f]{8}$/.test(hex);

/**
 * Converts a color name to hex.
 * @param color a named color
 * @returns hex color
 */
export const convertColorNameToHex = (color: string): string => {
    const ctx = document.createElement('canvas').getContext('2d');
    if (!ctx) return '#000000';
    ctx.fillStyle = color;
    return ctx.fillStyle.toUpperCase();
};

/**
 * Determines if the text should be light or dark based on the background color.
 * @param hex hex color
 * @returns true if text should be light, false if text should be dark
 */
export const shouldTextBeLightOnBackgroundColor = (hex: string): boolean => {
    // Remove the hash if it's there
    hex = hex.replace('#', '');

    // Convert hex to RGB
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Calculate the luminance https://en.wikipedia.org/wiki/YIQ
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;

    // Return false for light backgrounds and true for dark backgrounds
    return yiq >= 128 ? false : true;
};
