import { useLayoutEffect } from 'react';

/**
 * Remove the sroll bar on the body tag of the page on mount.
 * Restore the original body scroll state on unmount.
 */
const useRemoveBodyScroll = (): void => {
    useLayoutEffect(() => {
        // Get original body overflow
        const originalStyle = window.getComputedStyle(document.body).overflow;
        // Prevent scrolling on mount
        document.body.style.overflow = 'hidden';
        // Re-enable scrolling when component unmounts
        return () => {
            document.body.style.overflow = originalStyle;
        };
    }, []); // Empty array ensures effect is only run on mount and unmount
};

export default useRemoveBodyScroll;
