import * as MaxRectsPacker from 'maxrects-packer';

export interface GridItem<K> {
    width: number; // width is required
    height: number; // height is required
    itemKey: string;
    item: K;
}

export interface CalculatedGridItem<K> extends GridItem<K> {
    x: number; // x position of the item
    y: number; // y position of the item
    scale: number; // scale of the item
}

interface ScaleOptions {
    maxWidth: number; // max width of the item when it's scaled
    maxHeight: number; // max height of the item when it's scaled
}

type Props<T> = {
    items: GridItem<T>[]; // the list of items (e.g. formats)
    maxWidth?: number; // max width of the container
    maxHeight?: number; // max height of the container
    padding?: number; // padding of the container
    gap?: number; // gap between the items
    enableScaling?: boolean; // whether or not to scale the items (default true)
    scaleOptions?: ScaleOptions; // the options for the scaling per item
    packerOptions?: MaxRectsPacker.IOption; // the options for the packer library (follow the IOption Interface for more explanation)
    extraFormatHeight?: number; // extra height of the item (e.g. if a format has a header or footer)
    extraFormatWidth?: number; // extra width of the item
    zoom?: number; // If the canvas is zoomed in, the packer needs to know this to calculate the correct x and y values, because the extraFormatHeight and extraFormatWidth are not zoomed in
};

const DEFAULT_PACKER_OPTIONS: MaxRectsPacker.IOption = {
    smart: false,
    border: 0
};

// The minimum difference between the max width and the scale options max width. If the maxWidth (so the wrapper size) is smaller
// than the scaleOptions.maxWidth, we need to adjust the scaleOptions.maxWidth, otherwise the creative will not be included in the packer
const MIN_DIFFERENCE_MAX_WIDTH = 52;

/**
 * This function packs (arranges) a list of items (e.g. formats) into a container using the maxrects-packer library.
 * It returns the list of items with the correct x, y and scale values.
 */
const arrangeItemsInGrid = <T>({
    items,
    maxWidth = 1024,
    maxHeight = Infinity,
    gap = 16,
    enableScaling = true,
    scaleOptions = {
        maxWidth: 600,
        maxHeight: 400
    },
    packerOptions,
    extraFormatHeight = 0,
    extraFormatWidth = 0,
    zoom = 1
}: Props<T>): CalculatedGridItem<T>[] => {
    if (items.length === 0) return [];

    // Overwrite the packer options if they ar/e provided
    const mergedPackerOptions = { ...DEFAULT_PACKER_OPTIONS, ...packerOptions };

    // If scaling is enabled, we need to make sure the scaleOptions.maxWidth is smaller than the maxWidth and some extra space for padding
    if (enableScaling && maxWidth < scaleOptions.maxWidth + MIN_DIFFERENCE_MAX_WIDTH) {
        scaleOptions.maxWidth = scaleOptions.maxWidth - MIN_DIFFERENCE_MAX_WIDTH;
    }

    // Initialize the packer
    const packer = new MaxRectsPacker.MaxRectsPacker(maxWidth, maxHeight, gap, mergedPackerOptions);

    // Format the items to include the optional scaling and extra height/width per format. The library needs these updated
    // values to calculate the correct position of the items. This is just to get the correct x and y values of the items.
    // Afterwards we put the original width/height of the items back
    const formattedItems: any[] = items.map((item) => {
        const scale = enableScaling ? getItemScale<T>(item, scaleOptions) : 1;

        return {
            ...item,
            width: item.width * scale + extraFormatWidth / zoom,
            height: item.height * scale + extraFormatHeight / zoom,
            originalHeight: item.height,
            originalWidth: item.width,
            scale
        };
    });

    // Add the items to the packer
    packer.addArray(formattedItems as unknown as MaxRectsPacker.Rectangle[]);

    // Get the rects with the correct x and y values. Get the first bin only because we only use 1 bin.
    const rects = packer.bins[0].rects;

    // Put the original width/height of the items back
    const formattedRects: CalculatedGridItem<T>[] = rects.map((rect: any) => {
        const newRect = {
            ...rect,
            width: rect.originalWidth,
            height: rect.originalHeight
        };

        // Delete temporary and unnecessary properties
        delete newRect.originalWidth;
        delete newRect.originalHeight;
        delete newRect.rot;

        return newRect;
    });

    return formattedRects;
};

const getItemScale = <T>(item: GridItem<T>, scaleOptions: ScaleOptions) => {
    const { maxWidth, maxHeight } = scaleOptions;
    const { width, height } = item;

    if (width > height && width > maxWidth) {
        return Number((Math.round((maxWidth / width) * 20) / 20).toFixed(2));
    } else if (height > maxHeight) {
        return Number((Math.round((maxHeight / height) * 20) / 20).toFixed(2));
    } else {
        return 1;
    }
};

export default arrangeItemsInGrid;
