import { useEffect, useState } from 'react';
import { EventEmitterPayloads, EventEmitterTypes } from 'types/event-emitter.type';
import { EventEmitterHelpers } from 'helpers/event-emitter.helpers';

/**
 * Hook to receive an event emitter.
 * @param type - The type of the event emitter.
 */
const useEventEmitterListener = <T extends EventEmitterTypes>(type: T): EventEmitterPayloads[T] | null => {
    /**
     * Data from the event emitter.
     */
    const [data, setData] = useState<EventEmitterPayloads[T] | null>(null);

    /**
     * Receive an event emitter.
     */
    useEffect(() => {
        EventEmitterHelpers.receive(type, handleEvent);
        EventEmitterHelpers.setMax();
        return () => EventEmitterHelpers.remove(type, handleEvent);
    }, [type]);

    /**
     * Handle the event.
     * @param data - Data from the event.
     */
    const handleEvent = (data: EventEmitterPayloads[T]) => {
        setData(data);
    };

    return data;
};

export { useEventEmitterListener };
