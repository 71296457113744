export type OS = 'Windows' | 'MacOS' | 'Linux' | 'Unknown';

/**
 * Detect which OS is running Campaign Designer.
 * @returns Which OS is running Campaign Designer.
 */
const whichOS = (): OS => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.includes('windows')) {
        return 'Windows';
    } else if (userAgent.includes('macintosh') || userAgent.includes('mac os')) {
        return 'MacOS';
    } else if (userAgent.includes('linux')) {
        return 'Linux';
    }

    return 'Unknown';
};

export { whichOS };
