import { cloneDeep as lodashCloneDeep } from 'lodash';

/**
 * This function clones a value without keeping the deep references that normal spread operator would have. StructuredClone is not yet available
 * on all browsers, so as a fallback we use the normal cloneDeep from lodash.
 * We use this because structuredClone is a lot faster than cloneDeep from lodash, especially for large objects
 * @param {*} value The object or array that has to be cloned
 * @returns A cloned value without references
 */
const cloneDeep = (value) => {
    try {
        return structuredClone(value);
    } catch (error) {
        return lodashCloneDeep(value);
    }
};

export default cloneDeep;
