import EventEmitter from 'events';
import { EventEmitterPayloads, EventEmitterTypes } from 'types/event-emitter.type';

/**
 * Helper to sent, receive or remove event emitters.
 */
class EventEmitterHelpers {
    static eventEmitter = new EventEmitter();

    /**
     * Send an event emitter.
     * @param type - The type of the event emitter.
     * @param data - The data to send.
     */
    static sent<T extends EventEmitterTypes>(type: T, data: EventEmitterPayloads[T]): void {
        this.eventEmitter.emit(type, data);
    }

    /**
     * Receive an event emitter.
     * @param type - The type of the event emitter.
     * @param callback - The callback function.
     */
    static receive<T extends EventEmitterTypes>(type: T, callback: (data: EventEmitterPayloads[T]) => void): void {
        this.eventEmitter.on(type, callback);
    }

    /**
     * Remove an event emitter.
     * @param type - The type of the event emitter.
     * @param callback - The callback function.
     */
    static remove<T extends EventEmitterTypes>(type: T, callback: (data: EventEmitterPayloads[T]) => void): void {
        this.eventEmitter.removeListener(type, callback);
    }

    /**
     * Remove all event emitters.
     */
    static removeAll(): void {
        this.eventEmitter.removeAllListeners();
    }

    /**
     * Set the max amount of listeners.
     */
    static setMax(amount = 40): void {
        this.eventEmitter.setMaxListeners(amount);
    }
}

export { EventEmitterHelpers };
