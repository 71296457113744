import { RefObject, useEffect, useMemo, useState } from 'react';

/**
 * Hook to check if an element is in view.
 * @param ref The reference to the element.
 * @returns True if the element is in view.
 */
const useIsInView = (ref: RefObject<HTMLElement>): boolean => {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = useMemo(() => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting)), [ref]);

    useEffect(() => {
        if (ref.current) observer.observe(ref.current);
        return () => observer.disconnect();
    }, [ref]);

    return isIntersecting;
};

export { useIsInView };
