export type Expression =
    | 'email'
    | 'url'
    | 'extendedUrl'
    | 'hexColor'
    | 'dateISO'
    | 'time24Hour'
    | 'dateTimeISO'
    | 'alphanumeric'
    | 'onlyLetters'
    | 'onlyNumbers'
    | 'whitespace'
    | 'notWhitespace'
    | 'transformScale'
    | 'transformRotate'
    | 'rgbColor'
    | 'boxShadow'
    | 'valueAndUnit'
    | 'dynamicValue'
    | 'fileExtension'
    | 'startsWithSpecialChar'
    | 'maxThreeOneCharWords'
    | 'restrictedSpecialChar'
    | 'containsNumbers';

/**
 * Regular expression helpers.
 */
class RegexHelpers {
    /**
     * Regular expressions.
     */
    private static expressions: Record<Expression, RegExp> = {
        email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        url: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
        extendedUrl: /\b(?:https?:\/\/)?(?:www\.)\S+\.\S+\b/gm,
        hexColor: /^#?([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/,
        dateISO: /^\d{4}-\d{2}-\d{2}$/,
        time24Hour: /^([01]\d|2[0-3]):([0-5]\d)$/,
        dateTimeISO: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})?$/,
        alphanumeric: /^[a-zA-Z0-9]+$/,
        onlyLetters: /^[a-zA-Z]+$/,
        onlyNumbers: /^[0-9]+$/,
        whitespace: /\s+/,
        notWhitespace: /\S/,
        valueAndUnit: /[a-zA-Z]+|[-]+[0-9.]+|[0-9.]+|[%]+/g,
        transformScale: /scale\((.*?)\)/,
        transformRotate: /rotate\((.*?)\)/,
        rgbColor: /rgba?\((\d{1,3}), (\d{1,3}), (\d{1,3})(?:, ([\d.]+))?\)/,
        boxShadow: /rgba?\((\d+), (\d+), (\d+)(?:, (\d+(\.\d+)?))?\)|(-?\d+px)|inset/g,
        dynamicValue: /%\[.*?\]%/,
        fileExtension: /(?:\.([^.]+))?$/,
        startsWithSpecialChar: /^[/!.?\-*(),;:]/, // Ad Validators: / ! . ? - * ( ) , ; :
        maxThreeOneCharWords: /(?:\b\w\b[\s.,]*){4,}/, // Ad Validators: Max 3 one-char words
        restrictedSpecialChar: /[\\^~_={}[\]|<>]/g, // Ad Validators: Special characters ^ ~ _ = { } [ ] | < >
        containsNumbers: /\d+/g
    };

    /**
     * Get the regular expression by its name.
     * @param expression - The name of the regular expression.
     * @returns - The corresponding regular expression.
     */
    static getRegexExpression(expression: Expression): RegExp {
        return this.expressions[expression];
    }

    /**
     * Validate a value against a regular expression.
     * @param expression - Regular expression to validate against.
     * @param value - Value to validate.
     * @returns - True if the value is valid, false otherwise.
     */
    static validate(expression: Expression, value: string): boolean {
        const regex = this.expressions[expression];
        return regex.test(value);
    }

    /**
     * Extract matches of a regular expression from a string.
     * @param expression - Regular expression to match.
     * @param value - String to search.
     * @returns - Array of matches.
     */
    static extractMatches(expression: Expression, value: string): string[] {
        const regex = this.expressions[expression];
        return value.match(regex) || [];
    }

    /**
     * Replace matches of a regular expression in a string.
     * @param expression - Regular expression to match.
     * @param value - String to search.
     * @param replacement - Replacement string.
     * @returns - Modified string.
     */
    static replaceMatches(expression: Expression, value: string, replacement: string): string {
        const regex = this.expressions[expression];
        return value.replace(regex, replacement);
    }

    /**
     * Split a string by a regular expression.
     * @param expression - Regular expression to split by.
     * @param value - String to split.
     * @returns - Array of substrings.
     */
    static splitByExpression(expression: Expression, value: string): string[] {
        const regex = this.expressions[expression];
        return value.split(regex);
    }

    /**
     * Check if the entire string matches a regular expression.
     * @param expression - Regular expression to match.
     * @param value - String to check.
     * @returns - True if the entire string matches, false otherwise.
     */
    static isFullMatch(expression: Expression, value: string): boolean {
        const regex = this.expressions[expression];
        return regex.test(value) && regex.source === `^${value}$`;
    }
}

export { RegexHelpers };
