import set from 'lodash/set';
import unset from 'lodash/unset';
import { History as HistoryType } from '../types/history.type';

/**
 * This is a class that handles history in one static variable.
 */
class History {
    static history: HistoryType = {}; //History is what the models that were changed looked like before the change.
    static reverseHistory: HistoryType = {}; //Reverse history is what the models that were changed looked like after the change just like history but it is only used if undo is activated and is used on activation of redo.

    /**
     * Save the current state to history.
     * @param currentValue The current state to save to history.
     */
    static save = <Snapshot>(currentValue: Snapshot, store: string, subStore: string, maxHistoryLength = 10): void => {
        if (!this.history[store]?.[subStore]) set(this.history, `${store}.${subStore}`, []);
        const history = this.history[store]?.[subStore];

        const currentTimeMS = new Date().getTime();
        // If current time is less than 250ms after last save, don't save
        if (history.length > 0) {
            const lastSave = history[0].time;
            if (currentTimeMS - lastSave < 250) return;
        }
        // add to history
        history.unshift({
            time: currentTimeMS,
            data: currentValue
        });

        // Limit history length.
        if (history.length > maxHistoryLength) {
            history.pop();
        }
        // Empty reverseHistory.
        if (this.reverseHistory[store]?.[subStore]) {
            unset(this.reverseHistory, `${store}.${subStore}`);
        }
    };

    /**
     * Undo the last change.
     * @param onUndo A function that re applies the changes from the snapshot to the store and returns the way the store was before doing that.
     */
    static undo = <Snapshot>(onUndo: (snapShot: Snapshot) => Snapshot | undefined, store: string, subStore: string, maxHistoryLength = 10): void => {
        const history = this.history[store]?.[subStore];
        if (!history || history.length === 0) return;

        // Remove from history.
        const snapShot: Snapshot = history.shift();
        if (!snapShot) return;

        // Add to reverse history.
        // Check if reverse history exists, if not create it.
        if (!this.reverseHistory[store]?.[subStore]) set(this.reverseHistory, `${store}.${subStore}`, []);
        const reverseHistory = this.reverseHistory[store][subStore];
        // Add new snapshot with current data ( before the undo action ) to reverse history.
        const undoData = onUndo(snapShot);
        reverseHistory.unshift(undoData);
        // Check length of reverse history and remove the last item if it exceeds the limit.
        if (reverseHistory.length > maxHistoryLength) {
            reverseHistory.pop();
        }
    };

    /**
     * Redo the last undo.
     * @param onRedo A function that re applies the previous undo from the snapshot to the store and returns the way the store was before doing that.
     */
    static redo = <Snapshot>(onRedo: (snapShot: Snapshot) => Snapshot | undefined, store: string, subStore: string, maxHistoryLength = 10): void => {
        const reverseHistory = this.reverseHistory[store]?.[subStore];
        if (!reverseHistory || reverseHistory?.length === 0) return;
        // Remove from reverseHistory.
        const snapShot: Snapshot = reverseHistory.shift();
        if (!snapShot) return;

        // Add to history.
        // Check if history exists, if not create it.
        if (!this.history[store]?.[subStore]) set(this.history, `${store}.${subStore}`, []);
        const history = this.history[store][subStore];
        // Add new snapshot with current data ( before the redo action ) to history.
        const redoData = onRedo(snapShot);
        history.unshift(redoData);
        // Check length of history and remove the last item if it exceeds the limit.
        if (history.length > maxHistoryLength) {
            history.pop();
        }
    };
}

export { History };
