import { useRef, useEffect } from 'react';

/**
 * A hook to get previous prop value of a component
 * @param value Prop to keep track of
 * @returns previous value of the prop
 */
function usePreviousValue<T>(value: T): T | undefined {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export { usePreviousValue };
