import { useLocation } from 'react-router-dom';

// Check if we're in the Editor, working on a campaign or campaign concept.
const useInCampaign = (): boolean => {
    const location = useLocation();
    const firstPathSegment = location.pathname.split('/')[1];
    return firstPathSegment === 'editor';
};

export { useInCampaign };
