const IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'svg', 'webp'];
const VIDEO_EXTENSIONS = ['mp4', 'avi', 'flv', 'mov', 'wmv', 'mkv', 'webm'];
const AUDIO_EXTENSIONS = ['mp3', 'wav', 'ogg', 'flac', 'aac', 'm4a', 'wma'];

type AssetFileType = 'image' | 'video' | 'audio';

class AssetHelper {
    /**
     * Get the file type based on the given extension.
     * @param extension The extension to get the file type for. For example 'jpg', 'mp4', 'bmp', etc.
     * @returns The file type of the given extension. For example 'image', 'video', or 'audio'.
     */
    static getFileType(extension?: string): AssetFileType | undefined {
        if (!extension) return;

        const lowerCaseExtension = extension.toLowerCase();
        const isImage = IMAGE_EXTENSIONS.includes(lowerCaseExtension);
        const isVideo = VIDEO_EXTENSIONS.includes(lowerCaseExtension);
        const isAudio = AUDIO_EXTENSIONS.includes(lowerCaseExtension);

        if (isImage) return 'image';
        if (isVideo) return 'video';
        if (isAudio) return 'audio';
        return;
    }

    /**
     * Check if the given URL is a video.
     * @param url The URL to check.
     * @returns True if the URL points to a video file, false otherwise.
     */
    static urlIsVideo(url: string): boolean {
        const extension = url.split('.').pop()?.toLowerCase() || '';
        return this.getFileType(extension) === 'video';
    }

    /**
     * Get the file type based on the given URL.
     * @param url The URL to get the file type for.
     * @returns The file type of the given URL. For example 'image', 'video', or 'audio'.
     */
    static getFileTypeFromUrl(url: string): AssetFileType | undefined {
        const extension = url.split('.').pop()?.toLowerCase() || '';
        return this.getFileType(extension);
    }
}

export default AssetHelper;
